import axios from "axios"
import { defaultURl } from "./url.service"

export class BufferService {
    constructor(name) {
        this.url = defaultURl
        this.entityName = name
    }

    async get() {
        const info = await axios.get(this.url + this.entityName)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return info
    }

    async post(data) {
        const res = await axios.post(this.url + this.entityName, data)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return res
    }

    async postQuery(data) {
        const res = await axios.post(this.url + this.entityName + data)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return res
    }

    async putQuery(data) {
        const res = await axios.put(this.url + this.entityName + data)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return res
    }

    async put(data) {
        const res = await axios.put(this.url + this.entityName, data)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return res
    }

    async postId(id) {
        const lists = await axios.post(this.url + this.entityName + '/' + id)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return lists
    }

    async deleteId(id) {
        const lists = await axios.delete(this.url + this.entityName + '/' + id)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return lists
    }

    async delete(data) {
        const lists = await axios.delete(this.url + this.entityName + data)
            .then(function (response) {
                return response.data
            })
            .catch(function (error) {
                return error
            })
        return lists
    }
}