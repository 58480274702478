import React, { useState } from 'react'
import { TextField, IconButton } from "@mui/material"
import PasswordChecklist from "react-password-checklist"
import { Visibility, VisibilityOff } from '@mui/icons-material'

export default function M_InputTextField({ label, type, value, onChange, disabled, maxNumbers,
    required, color, secondColor, size, placeholder, onKeyDown, height,
    padding, margin, backgroundColor, iconStart, iconEnd, passVerification, minLengthverification, valueAgain, disabledCheck }) {
    const isRequired = required ? required : false
    const [focused, setFocused] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    color = color ? color : 'var(--color-black)'
    secondColor = secondColor ? secondColor : 'var(--color-black)'

    function isNumber(e) {
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 188 || e.keyCode === 9 || e.keyCode === 13) {
            return true
        }
        e.preventDefault()
    }

    const styles = {
        margin: margin,
        width: size ? size : null,
        '& label': { color: secondColor ? secondColor : 'var(--color-black)', },
        '& label.Mui-focused': { color: secondColor ? secondColor : 'var(--color-black)' },
        '& .MuiInput-underline:after': { borderBottomColor: color ? color : 'var(--color-black)' },
        "& .MuiInputBase-root.Mui-disabled": {
            "& > fieldset": {
                borderColor: 'rgba(0, 0, 0, 0.38) !important'
            }
        },
        '& .MuiOutlinedInput-input': {
            backgroundColor: backgroundColor ? backgroundColor : 'var(--color-white)',
            padding: padding,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: color ? color : 'var(--color-black)',
                width: size ? size : 'auto',
                height: height,
            },
            '&:hover fieldset': {
                borderColor: color ? color : 'var(--color-black)',
            },
            '&.Mui-focused fieldset': {
                borderColor: color ? color : 'var(--color-black)',
                boxShadow: `2px 2px 2px ${color}`
            },
            "& legend": {
                letterSpacing: -1.9,
            }
        },
    }

    return (
        <>
            <TextField
                sx={styles}
                placeholder={placeholder}
                InputLabelProps={{ autoComplete: "none", shrink: true }}
                inputProps={{ maxLength: maxNumbers }}
                InputProps={{
                    startAdornment: (!disabled && iconStart ? iconStart : ''),
                    endAdornment: (!disabled && iconEnd ? iconEnd : type === 'password' ?
                        <IconButton
                            disableRipple
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev => !prev))}
                            edge="end"
                            sx={{
                                "&.Mui-focusVisible": {
                                    color: "var(--color-black)",
                                    backgroundColor: "var(--color-grey-light)"
                                },
                            }}
                        >
                            {showPassword ? <VisibilityOff style={{ color: 'var(--color-grey)' }} /> : <Visibility style={{ color: 'var(--color-grey)' }} />}
                        </IconButton> : '')
                }}
                required={isRequired}
                margin="dense"
                label={label}
                variant="outlined"
                fullWidth
                type={type === 'number' ? 'string' : type === 'password' ? showPassword ? "text" : "password" : type}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onFocus={() => setFocused(true)}
                onKeyDown={(e) => {
                    if (e.key === 'Escape' || e.key === 'Enter') {
                        setFocused(false)
                    }
                    if (onKeyDown) {
                        onKeyDown(e)
                    }
                    if (type === "number") {
                        isNumber(e)
                    }
                }}
                style={{ height: height }}
                size="small"
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                {type === 'password' && passVerification ?
                    <PasswordChecklist
                        rules={disabledCheck ?
                            ["minLength", "specialChar", "number", "capital", "lowercase"] :
                            ["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                        minLength={minLengthverification}
                        value={value}
                        valueAgain={valueAgain}
                        messages={{
                            minLength: (<span> Contém mais de {minLengthverification} caracteres </span>),
                            specialChar: (<span> Contém caracteres especiais</span>),
                            number: (<span> Contém números </span>),
                            capital: (<span> Contém maiúsculas </span>),
                            lowercase: (<span> Contém minúsculas </span>),
                            ...(disabledCheck ? {} : { match: (<span> Passwords coincidem </span>) })
                        }}
                    />
                    : <></>
                }
            </div>
        </>
    )
}