import React from 'react'
import M_MessageBoard from '../utils/M_MessageBoard/M_MessageBoard'
import M_Button from '../utils/M_Button/M_Button'

export default function ModalConfirmEmail({ setOpenModalEmailInfo, openModalEmailInfo }) {

  return (
    <M_MessageBoard
      warning
      onClose={() => setOpenModalEmailInfo(false)}
      width={"500px"}
      minWidth={"auto"}
      open={openModalEmailInfo}
      title={"Confirmar email"}
      body={
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span>Foi-lhe enviado um email para confirmar a sua conta.</span>
        </div>
      }
      footer={
        <M_Button
          height={40}
          width={10}
          bold
          label={"Entendi"}
          labelColor={'var(--color-white)'}
          onClick={() => setOpenModalEmailInfo(false)}
          borderRadius={15}
          hoverEffect
          backgroundColor={"var(--color-green)"}
          borderColor={"var(--color-green)"}
        />
      }
    />
  )
}