import React from 'react'
import './pages.css'
import { useNavigate } from 'react-router-dom'

export default function TermsAndConditions() {
    const navigate = useNavigate()
    return (
        <>
            <div className='headerPage'>
                <div className='divlogoCif'>
                    <div className='subDivLogoCif'>
                        <img src="/Images/logo.png" alt="logo" className='imgCifStyles' />
                        <div className='spansDivLogosCif'>
                            <span style={{ color: 'var(--color-black)', fontSize: '10px', width: '103px' }}>CLUB INTERNACINAL</span>
                            <span style={{ color: 'var(--color-black)', fontSize: '10px', width: '103px' }}>DE FOOT-BALL</span>
                        </div>
                    </div>
                </div>
                <div className='subDivMenu' style={{ alignItems: 'center' }}>
                    <div style={{ height: '43px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <button onClick={() => navigate('/')} className={"button"}>Voltar</button>
                    </div>
                </div>
            </div>
            <div>
                <h2 style={{ borderLeft: '10px solid var(--color-orange)', textTransform: 'uppercase', marginLeft: '10%' }}>Termos e condições</h2>
                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Informação geral</h4>
                        <div>
                            <p style={{ textTransform: 'inherit' }}>Ao aceder ao Website,
                                o utilizador deve tomar conhecimento das seguintes disposições aplicáveis a cada um dos documentos, informações, dados e requisitos que nele se encontram
                                e que são propriedade e estão sob a manutenção do CIF, Club Internacional de Foot-Ball, com sede na
                                <span style={{ fontStyle: 'italic' }}> Avenida dos Bombeiros, 1400-036</span>,
                                sob o número único de matrícula e de pessoa coletiva <span style={{ fontStyle: 'italic' }}>500065500</span>.
                            </p>
                            <p>O Website “MyCIF” é um sítio na Internet por meio do qual o CIF disponibiliza para aluguer, os seus courts de Ténis, de terra batida e de piso rápido, indoor e outdoor,
                                e onde disponibiliza informação sobre a sua atividade.</p>
                            <p>Através do Website, sujeito às presentes condições gerais de utilização, o utilizador poderá analisar
                                a disponibilidade dos campos, sendo que a informação apresentada reflete os Courts que se encontram disponíveis para alugar, no dia e hora pretendida.
                                O serviço é disponibilizado a utilizadores Sócios e Não Sócios. Os Não Sócios estão obrigados a efetuar o pagamento de uma fee, cujo valor dependerá do campo escolhido,
                                por forma a garantirem o aluguer pretendido. O pagamento será, neste caso, finalizado no dia de utilização do campo, antes da entrada no Court, junto da portaria do Club.
                                O aluguer do campo pressupõe a aceitação das condições aqui apresentadas. </p>
                            <p>Considera-se “Utilizador” todo o indivíduo, Sócio ou Não Sócio, ou instituição que, em geral, através do Website, visite o conteúdo no mesmo disponibilizado.</p>
                            <p>O Website contém textos, fotografias, gráficos, logotipos, imagens, obras sujeitas a direitos de autor e outros tipos de informação.</p>
                            <p>Para qualquer questão ou pedido de esclarecimentos relacionados com o site, poderá contactar-nos através de:</p>
                            <ul>
                                <li>Contacto e-mail: <a href="mailto:officecif@cif.org.pt">officecif@cif.org.pt</a> (dias úteis) ou <a href="mailto:portaria@cif.org.pt">portaria@cif.org.pt</a></li>
                                <li>Tel: <a href="tel:+351933546110">+351 933546110</a> ou <a href="tel:+351213014767">+351 213014767</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Acesso e aceitação</h4>
                        <div>
                            <p>O acesso e o uso das funcionalidades do Website encontram-se sujeitos às presentes Condições Gerais de Utilização,
                                bem como a quaisquer leis e regulamentos, nacionais ou comunitários, aplicáveis. O CIF recomenda que o Utilizador leia atentamente as presentes Condições Gerais
                                de Utilização. Se não aceitar e não se comprometer a respeitar cada um dos presentes termos e condições, não ficará autorizado a aceder ou a utilizar de outro modo
                                o Website. O acesso e a utilização pelos Utilizadores do Website implicam a sua aceitação e o compromisso da sua parte, de respeitar todos e cada um dos termos e
                                condições a seguir estabelecidos. Se estiver a utilizar o Website em nome de outrem ou em nome de uma instituição, confirma que aquele que está a representar também
                                concorda com os termos e condições, comprometendo-se a cumpri-los.
                            </p>
                            <p>As presentes Condições Gerais de Utilização poderão ser alteradas, modificadas, ampliadas ou atualizadas pelo CIF, em qualquer momento e sem aviso prévio. Quaisquer
                                conteúdos novos que se apresentem e/ou se disponibilizem no Website, ficarão também sujeitos às presentes Condições Gerais de Utilização, com efeitos a partir da data
                                da sua utilização. Recomendamos que o Utilizador consulte o Website de forma periódica, a fim de se manter atualizado relativamente a quaisquer alterações e atualizações.
                            </p>
                            <p>O Website não se destina a ser utilizado por menores de 18 (dezoito) anos, pelo que não são recolhidas, de forma intencional, informações que resultem da interação de menores
                                com o Website. O CIF não se responsabiliza pela interação ou acesso ao Conteúdo do Website por menores.</p>
                            <p>O acesso à plataforma para Sócios, prescindindo da obrigatoriedade de pagamento de uma fee para confirmação do aluguer, está condicionado ao registo prévio do Utilizador, bem
                                como à respetiva autenticação, através de identificação e palavra-passe, e posterior validação por parte do CIF. O CIF envidará os seus melhores esforços para manter o Website acessível.
                            </p>
                            <p>Não obstante, o CIF reserva-se o direito de suspender, temporariamente e sem aviso prévio, o acesso ao Website ou a determinadas áreas ou funcionalidades disponíveis no mesmo,
                                designadamente por razões de manutenção. O CIF não assume qualquer responsabilidade pelos prejuízos decorrentes de eventuais situações de impossibilidade, atraso, suspensão ou
                                interrupção no acesso ao Website em virtude de falhas técnicas ou outros motivos. O CIF não garante que o acesso ao Website não sofra interrupções ou perturbações resultantes
                                de problemas técnicos.
                            </p>
                            <p>O CIF reserva-se o direito de completar, modificar ou suprimir o Conteúdo e Funcionalidades do Website.</p>
                            <p><span style={{ fontWeight: 'bold', textTransform: 'inherit' }}>Sócios - Registo e Criação de Conta no Website</span></p>
                            <p>Para proceder ao aluguer de Campos, isentando-se do pagamento obrigatório, serviço só disponibilizado aos Sócios do CIF, é necessário aceder ao Website e criar conta como utilizador,
                                através do preenchimento do formulário eletrónico com os seus dados pessoais, seguindo as instruções indicadas em cada etapa.
                            </p>
                            <p>Os Dados Pessoais que forem voluntariamente fornecidos pelo Utilizador ficam sujeitos à nossa Política de Privacidade.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Correta utilização do Website</h4>
                        <div>
                            <p>O Utilizador compromete-se a fazer uma utilização adequada e lícita do Website, bem como dos conteúdos e Produtos disponibilizados, em conformidade com a legislação aplicável em cada momento.
                                O Utilizador deverá abster-se de:</p>
                            <ol type="a">
                                <li style={{ marginBottom: '10px' }}>Fazer um uso não autorizado ou fraudulento do Website e/ou do Conteúdo com fins ou efeitos ilícitos, lesivos dos direitos e interesses de terceiros, ou que de qualquer forma possam causar danos,
                                    inutilizar, sobrecarregar, deteriorar ou impedir a normal utilização dos serviços ou dos documentos;</li>
                                <li style={{ marginBottom: '10px' }}>Aceder ou tentar aceder a recursos ou áreas restringidas do Website sem cumprir as condições exigidas para tal acesso;</li>
                                <li style={{ marginBottom: '10px' }}>Introduzir ou difundir na rede programas, vírus, macros, applets, controles ActiveX ou qualquer outro dispositivo que cause ou seja suscetível de causar qualquer tipo de alteração nos sistemas
                                    informáticos do Website ou de terceiros;</li>
                                <li style={{ marginBottom: '10px' }}>Provocar danos nos sistemas físicos e lógicos do CIF, de pessoas a ele associadas ou de terceiros, introduzir ou difundir na rede vírus informáticos ou quaisquer outros sistemas físicos ou
                                    lógicos que sejam suscetíveis de provocar os danos anteriormente mencionados;</li>
                                <li style={{ marginBottom: '10px' }}>Copiar, reproduzir, recompilar, desmontar, realizar atividades de engenharia reversa, distribuir, publicar, mostrar, executar, modificar, importar, realizar trabalhos derivados, transmitir
                                    ou explorar partes do Website, excetuando a partilha de Conteúdos, sempre e quando o Utilizador reconheça todos os direitos de autor inerentes ao Website e respetivo Conteúdo;</li>
                                <li>Obter e tentar obter o Conteúdo disponibilizado no Website utilizando para tal meios ou procedimentos diferentes dos que, dependendo dos casos, tenham sido colocados à sua disposição ou que
                                    tenham sido indicados expressamente no Website ou, no geral, dos que se utilizem habitualmente na Internet por não representarem um risco de dano, inutilização ou uso fraudulento do Website
                                    e/ou do seu Conteúdo.</li>
                            </ol>
                            <p>O Utilizador garante que as atividades que desenvolva no uso ou acesso ao Website em nenhum caso resultarão ofensivas para o bom nome e imagem comercial do CIF ou de pessoas ou entidades a eles
                                associadas, para outros utilizadores do Website e Plataforma ou terceiros.</p>
                            <p>No caso de incumprimento de alguma das estipulações das presentes Condições Gerais de Utilização por parte do Utilizador, o CIF reserva-se ao direito de interromper momentânea ou definitivamente
                                o acesso do Utilizador ao Website.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Responsabilidade por uso indevido</h4>
                        <div>
                            <p>Caso o Utilizador utilize indevida e/ou ilicitamente o Website, em particular violando direta ou indiretamente as presentes Condições Gerais de Utilização, o Utilizador estará sujeito a
                                responder perante o CIF por qualquer dano ou prejuízo. Em caso de incumprimento das presentes Condições Gerais de Utilização, o CIF reserva-se o direito de cancelar de forma unilateral,
                                sob a sua exclusiva discricionariedade e sem necessidade de pré-aviso, o acesso, utilização, e/ou registo de qualquer Utilizador no Website, sem que isso dê lugar, em nenhum caso, a qualquer
                                indemnização ao Utilizador em questão.</p>
                            <p>O Utilizador compromete-se a informar o CIF, sem demora, sempre que observe uma utilização não autorizada do Website ou tiver conhecimento de que se está a produzir uma violação de um direito
                                do CIF ou de terceiros, ou se se estiver a levar a cabo uma utilização contrária às presentes Condições Gerais de Utilização.
                            </p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Informação sobre os Produtos e sobre preços</h4>
                        <div>
                            <p><span style={{ fontWeight: 'bold' }}>Informação dos Courts</span></p>
                            <p>Estão disponibilizados para aluguer 14 courts de terra batida, 9 outdoor e
                                5 indoor, e 5 courts de piso rápido, 3 cobertos e
                                2 exteriores, nos dias e horas apresentados.</p>
                            <p><span style={{ fontWeight: 'bold' }}>Informação de Preços</span></p>
                            <p>Os preços de aluguer estão disponibilizados no regulamento de utilização dos Courts em vigor, publicado no site do CIF
                                (<a href="https://www.cif.org.pt/pdf/regulamento-para-utilizacao-dos-campos-de-tenis.pdf" target="_blank" rel="noreferrer">aqui</a>).</p>
                            <p>Para formalização do aluguer na plataforma My CIF, será exigido a qualquer Não Sócio ou utilizador Sócio sem registo, o pagamento prévio de uma fee. Apenas mediante o pagamento deste valor
                                o Court ficará agendado. O pagamento do valor final do consumo deverá ser efetuado na portaria, antes da utilização do Court.</p>
                            <p>Os utilizadores registados, Sócios do CIF, pagarão, como habitualmente, na portaria do CIF.</p>
                            <p>No caso de ocorrência de erro informático, manual, técnico, ou de qualquer outra origem, que cause uma alteração substancial não prevista na reserva efetuada, ou sobreposição de reservas,
                                o pedido de aluguer poderá ser considerado inválido e anulado, devendo o CIF informar o utilizador sobre a questão.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Condições de compra</h4>
                        <div>
                            <p><span style={{ fontWeight: "bold" }}>Disponibilidade</span></p>
                            <p>Os Courts são apresentados com a disponibilidade on time e para 7 dias contados a partir da data em que o utilizador acede. As reservas na plataforma só poderão ser efetuadas com a antecedência
                                mínima de duas horas em relação à hora de início do aluguer.</p>
                            <p><span style={{ fontWeight: "bold" }}>Regulamento de utilização</span></p>
                            <p>O aluguer de qualquer Court através da plataforma My CIF pressupõe a leitura e aceitação do regulamento de utilização dos Courts (
                                <a href="https://www.cif.org.pt/pdf/regulamento-para-utilizacao-dos-campos-de-tenis.pdf" target="_blank" rel="noreferrer">aqui</a>).</p>
                            <p><span style={{ fontWeight: "bold" }}>Cancelamento do Aluguer</span></p>
                            <p>Sem prejuízo do Direito de Livre Resolução, o cliente pode solicitar o cancelamento do aluguer, através do link disponibilizado no email de confirmação da marcação, e até
                                24h antes. Caso o cancelamento aconteça fora deste prazo, o valor pago não será devolvido.</p>
                            <p><span style={{ fontWeight: "bold" }}>Erros</span></p>
                            <p>Nos termos do indicado, ressalvam-se eventuais erros de programação, defeitos no funcionamento do sistema informático (máquina) ou deformação da mensagem (incluindo lapsos de escrita),
                                nos termos e para os efeitos do previsto nas alíneas a) e b) do n.º 2 do artigo 33.º do Decreto-Lei n.º 7/2004, de 7 de janeiro.</p>
                            <p>No caso de ocorrência de erro informático, manual, técnico, ou de qualquer outra origem, que cause uma alteração substancial não prevista na reserva efetuada, ou sobreposição de reservas, o pedido
                                de aluguer poderá ser considerado inválido e anulado, devendo o CIF informar o utilizador sobre a questão.</p>
                            <p><span style={{ fontWeight: "bold" }}>Meios de Pagamento</span></p>
                            <p>Para a comodidade do Cliente, o CIF disponibiliza na plataforma MyCIF os seguintes meios de pagamento;</p>
                            <ul>
                                <li>Cartão Visa, MasterCard ou American Express;</li>
                                <li>MB Way.</li>
                            </ul>
                            <p>Os serviços de processamento de pagamentos online oferecidos são fornecidos pela Ifthenpay. A Ifthenpay é uma instituição de pagamento autorizada a exercer esta atividade nos países da Área Económica Europeia.
                                A sua sede fica em Portugal e é  supervisionada pelo Banco de Portugal (Nº de Registo 8707).</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Direitos do consumidor</h4>
                        <div>
                            <p>O acordo celebrado no momento de aluguer do campo será regido, em todos os aspetos, pelas leis portuguesas. O consumidor beneficia do regime legal de defesa de direitos dos consumidores
                                em conformidade com as normas legais e regulamentares vigentes em Portugal.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Exclusão de garantias e responsabilidades</h4>
                        <div>
                            <p>O acesso e a utilização do Website são da total responsabilidade do Utilizador. O Utilizador reconhece que utiliza o Website por sua conta e responsabilidade.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Responsabilidade civil</h4>
                        <div>
                            <p>O CIF, os seus administradores, diretores e empregados, sócios, sucessores e cessionários, não serão responsáveis, direta ou indiretamente, de nenhuma maneira,
                                face ao Utilizador ou a qualquer outra pessoa por: (i) erros e omissões do Website; (ii) atrasos, erros ou interrupções na transmissão de conteúdos através do
                                Website; (iii) por qualquer outra razão de incumprimento.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Política de privacidade</h4>
                        <div>
                            <p>Os Dados Pessoais fornecidos pelo titular através da interação com o Website ou no uso das suas funcionalidades serão processados e armazenados informaticamente pelo CIF.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Política de cookies</h4>
                        <div>
                            <p>A plataforma MyCIF não utiliza Cookies.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Comunicações e reclamações</h4>
                        <div>
                            <p>Qualquer comunicação que o utilizador deseje realizar ao CIF, designadamente reclamações, deverá realizá-la dirigindo a esta uma comunicação escrita através dos
                                contactos e meios disponibilizados no Website.</p>
                            <p>O Utilizador aceita expressamente a utilização do correio eletrónico como procedimento válido para a remissão de todas as comunicações relacionadas com a
                                utilização do Website, e/ou aluguer dos Courts ou informação sobre a atividade.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Legislação aplicável</h4>
                        <div>
                            <p>As presentes Condições de Utilização regem-se pela lei portuguesa. Caso uma qualquer disposição venha a ser considerada ilícita ou inaplicável por um tribunal
                                da jurisdição competente, tal não afetará a validade e aplicabilidade das restantes disposições.</p>
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: 'var(--color-white)', marginTop: '10px', marginBottom: '10px', marginLeft: '10%', marginRight: '10%', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <h4 style={{ textTransform: 'inherit' }}>Resolução de litígios</h4>
                        <div>
                            <p>Caso tenha uma reclamação ou queixa sobre as Condições de Utilização do Website, deve remeter uma comunicação escrita através dos contactos e meios
                                disponibilizados no Website. O CIF responder-lhe-á com a maior brevidade possível, propondo-lhe uma solução que vá de encontro à questão apresentada.</p>
                            <p>Qualquer litígio ou reclamação derivada ou relacionada com as presentes Condições Gerais de Utilização ou com a utilização do Website, deverá ser submetido
                                aos tribunais judiciais competentes.</p>
                            <p>O Utilizador poderá, ainda, recorrer à entidade de resolução alternativa de litígios competente.</p>
                            <p>Sem prejuízo do disposto na legislação, nos estatutos e nos regulamentos a que as entidades de resolução alternativa de litígios se encontram vinculadas, o
                                Utilizador poderá optar pela plataforma europeia de resolução de litígios em linha disponível
                                em <a href="https://webgate.ec.europa.eu/odr" target="_blank" rel="noreferrer">https://webgate.ec.europa.eu/odr</a>, pela entidade de resolução alternativa de litígios do local do seu
                                domicílio ou pela entidade de resolução alternativa de competência especializada.</p>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}