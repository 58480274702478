import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'

export default function LoadingBar({ }) {
    return (
        <div style={{ width: '100%' }}>
            <LinearProgress style={{ height: '5px' }}
                sx={{
                    backgroundColor: 'var(--color-grey-light)',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: 'var(--color-orange)'
                    }
                }}
            />
        </div>
    )
}