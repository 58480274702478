import React, { useState, useContext } from 'react'
import M_InputTextField from '../utils/M_InputTextField/M_InputTextField'
import M_RadioButton from '../utils/M_RadioButton/M_RadioButton'
import M_Button from '../utils/M_Button/M_Button'
import './profile.css'
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { IconButton } from '@mui/material'
import InputPhoneNumber from '../utils/InputPhoneNumber'
import { isValidPhoneNumber } from 'libphonenumber-js'
import LoadingContext from '../utils/loading.context'
import EditProfile from './editProfile'
import EditPassword from './editPassword'

export default function Profile() {
    const ctx = useContext(LoadingContext)
    const [editProfile, setEditProfile] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    //principal user dictionary
    const [userAccount, setUserAccount] = useState(new Map())

    //utils to check number
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const handlePhoneNumberChange = (value) => {
        setPhoneNumberValid(isValidPhoneNumber(value))
    }

    const updateAccount = (event, index) => {
        if (index === "Phone" || (event && event.target)) {
            const value = index === "Phone" ? event : event.target.value
            userAccount.set(index, value)
            setUserAccount(new Map(userAccount))
        }
    }

    const handleRadioChange = (e, code) => {
        const selectedValue = e.target.value
        userAccount.set(code, selectedValue)
        setUserAccount(new Map(userAccount))
    }

    const inputs = [
        { label: "Nome", type: "text", code: "Name" },
        { label: "Apelido", type: "text", code: "Surname" },
        { label: "NIF", type: "number", code: "Nif" },
        { label: "Telemóvel", type: "tel", code: "Phone" },
        { label: "Email", type: "email", code: "Email" },
        { label: "Password antiga", type: "password", code: "OldPassword" },
        {
            label: "Sócio", type: "radio", code: "Partner", options: [
                { label: "Não", value: "nao" },
                { label: "Sim", value: "sim" }
            ]
        },
        { label: "Nº Sócio", type: "number", code: "PartnerNumber" },
        { label: "Nova Password", type: "passwordNew", code: "NewPassword" },
    ]

    const renderInput = (value) => {
        if (value.type === "text") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"text"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    onChange={(e) => updateAccount(e, value.code)}
                    value={userAccount.get(value.code) || ""}
                />
            )
        } else if (value.type === "number") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"number"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    maxNumbers={9}
                    onChange={(e) => { updateAccount(e, value.code) }}
                    value={userAccount.get(value.code) || ""}
                    // disabled={value.code === "PartnerNumber" && userAccount.get("Partner") !== "sim"}
                    disabled={value.code === "PartnerNumber" && !(userAccount.get("WasPartner") === true && userAccount.get("Partner") === "nao")}

                />
            )
        } else if (value.type === "email") {
            return (
                <M_InputTextField
                    disabled
                    label={value.label}
                    type={"email"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    value={userAccount.get(value.code) || ""}
                    onChange={(e) => updateAccount(e, value.code)}
                />
            )
        } else if (value.type === "password") {
            return (
                <M_InputTextField
                    label={value.label}
                    type={showPassword ? "text" : "password"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    value={userAccount.get(value.code) || ""}
                    onChange={(e) => updateAccount(e, value.code)}
                />
            )
        } else if (value.type === "passwordNew") {
            return (
                <M_InputTextField
                    label={value.label}
                    type={showPassword ? "text" : "password"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    value={userAccount.get(value.code) || ""}
                    onChange={(e) => updateAccount(e, value.code)}
                    passVerification
                    disabledCheck
                    minLengthverification={6}
                />
            )
        } else if (value.type === "radio") {
            return (
                <M_RadioButton
                    disabled
                    label={value.label}
                    options={value.options}
                    selectedValue={userAccount.get(value.code) || ""}
                // onChange={(e) => {
                //     updateAccount(e, value.code)
                //     handleRadioChange(e, value.code)
                //     if (value.code === "Partner" && e.target.value === "nao") {
                //         updateAccount({ target: { value: "" } }, "PartnerNumber")
                //     }
                // }}
                />
            )
        } else if (value.type === "tel") {
            return (
                <InputPhoneNumber
                    required
                    label={value.label}
                    onChange={(e) => {
                        updateAccount(e, value.code)
                        handlePhoneNumberChange(e)
                    }}
                    value={userAccount.get(value.code) || ""}
                    error={!phoneNumberValid}
                    helperText={!phoneNumberValid ? "Número de telemóvel inválido" : "Número de telemóvel válido"}
                />
            )
        } else {
            return null
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='contentorProfile'>
                <div className='secoundDivProfile'>
                    <div style={{
                        display: 'flex', borderBottom: '1px solid var(--color-grey)', paddingLeft: '20px',
                        justifyContent: 'flex-start', alignItems: 'center', width: '100%', marginBottom: '5px'
                    }} >
                        <div style={{ margin: '10px' }}>
                            <M_Button
                                height={40}
                                width={10}
                                bold
                                label={"Editar perfil"}
                                labelColor={'var(--color-black)'}
                                onClick={() => setEditProfile(true)}
                                borderRadius={5}
                                hoverEffect
                                backgroundColor={"var(--color-white)"}
                                borderColor={"var(--color-grey-light)"}
                            />
                        </div>
                        <M_Button
                            height={40}
                            width={10}
                            bold
                            label={"Mudar password"}
                            labelColor={'var(--color-black)'}
                            onClick={() => setEditProfile(false)}
                            borderRadius={5}
                            hoverEffect
                            backgroundColor={"var(--color-white)"}
                            borderColor={"var(--color-grey-light)"}
                        />
                    </div>
                    {editProfile ?
                        <EditProfile
                            ctx={ctx}
                            inputs={inputs}
                            renderInput={renderInput}
                            userAccount={userAccount}
                            editProfile={editProfile}
                            phoneNumberValid={phoneNumberValid}
                            setUserAccount={setUserAccount}
                            setPhoneNumberValid={setPhoneNumberValid}
                        />
                        :
                        <EditPassword
                            ctx={ctx}
                            inputs={inputs}
                            renderInput={renderInput}
                            userAccount={userAccount}
                            setUserAccount={setUserAccount}
                        />
                    }
                </div>
            </div>
        </div>
    )
}