import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

export default function M_MessageBoard({ title, open, onClose, body, footer, minWidth, warning, width, onSubmit }) {
    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: minWidth, width: width } }}>
            <form onSubmit={onSubmit} style={{ width: '100%' }}>
                <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
                    {warning && <WarningAmber style={{ color: 'var(--color-red)', marginRight: '10px' }} />}
                    <b>{title}</b>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {body}
                </DialogContent>
                <Divider />
                <DialogActions style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '64px' }}>
                    {footer}
                </DialogActions>
            </form>
        </Dialog>
    )
}