import React from 'react'
import { FormControlLabel, Checkbox, Tooltip } from '@mui/material'

export function M_CheckBox({ label, checked, icon, checkedIcon, onChange, disabled, color, fontSize, required, labelColor, tooltipName }) {

    return (
        <Tooltip title={tooltipName ? tooltipName : ""}>
            <FormControlLabel
                sx={{
                    margin: !label ? '0px' : '5px 0px',
                    color: labelColor ? labelColor : 'var(--color-black)',
                    '& .MuiSvgIcon-root': { fontSize: fontSize },
                }}
                label={label}
                control={
                    <Checkbox
                        checked={checked}
                        disableRipple={true}
                        sx={{
                            color: color ? color : 'var(--color-black)',
                            '&.Mui-checked': {
                                color: color ? color : 'var(--color-black)',
                            },
                            '& .MuiSvgIcon-root': { fontSize: fontSize },

                        }}
                        icon={icon}
                        disabled={disabled}
                        checkedIcon={checkedIcon}
                        onChange={onChange}
                        required={required}
                    />}
            />
        </Tooltip>
    )
}