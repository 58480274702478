import React from 'react'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'

export default function M_Select({ label, value, options, color, disabled, required, onChange, letterColor }) {
    return (
        <FormControl
            sx={{
                '& label': { color: letterColor },
                '& label.Mui-focused': { color: letterColor, borderColor: color ? color : 'var(--color-black)' },
                '& .MuiInput-underline:after': { color: color, borderColor: "transparent", borderBottomColor: 'var(--color-black)' },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'var(--color-black)',
                    },
                    '&:hover fieldset': {
                        borderColor: color ? color : 'var(--color-black)',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: color ? color : 'var(--color-black)',
                    },
                },
            }}
            margin="dense"
            fullWidth
            size='small'
            style={{ marginRight: '10px' }}
            required={required}
        >
            <InputLabel>{label}</InputLabel>
            <Select label={label} value={value} disabled={disabled} onChange={onChange}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 155,
                            "&& .Mui-selected": {
                                backgroundColor: "var(--color-grey-light) !important"
                            },
                            "&& .Mui-selected:hover": {
                                backgroundColor: "var(--color-grey-light) !important"
                            }
                        }
                    }
                }}
            >
                {/* <MenuItem value="" > <em>None</em> </MenuItem> */}
                {options.map((el, index) => (
                    <MenuItem key={index} disableRipple={true} value={el}> {el} </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}