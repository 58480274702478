import React, { useState, useEffect } from 'react'
import './homePageTabs.css'
import { IconButton, Tooltip, Menu, MenuItem } from "@mui/material"
import { useMediaQuery } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import PersonIcon from '@mui/icons-material/Person'
import AssignmentIcon from '@mui/icons-material/Assignment'
import LoginIcon from '@mui/icons-material/Login'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'

export default function HomePageTabs({ t, setActiveReservations, setActiveDescription, activeDescription, activeReservations, setActiveProfile, activeProfile }) {
    const navigate = useNavigate()
    const matches = useMediaQuery('(max-width:975px)')
    const matchesMenu = useMediaQuery('(max-width:575px)')

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleLogout = () => {
        localStorage.clear()
        sessionStorage.clear()
        navigate(`/login`)
    }

    const buttons = [
        {
            label: "Reservas",
            click: [true, false, false],
            src: AssignmentIcon
        },
        {
            label: "Informações",
            click: [false, true, false],
            src: InfoIcon
        },
        {
            label: "Perfil",
            click: [false, false, true],
            src: PersonIcon
        },
        {
            label: "Logout",
            click: handleLogout,
            src: LoginIcon
        }
    ]

    const onClickButton = (clickArray) => {
        setActiveReservations(clickArray[0])
        setActiveDescription(clickArray[1])
        setActiveProfile(clickArray[2])
    }

    useEffect(() => {
        if (!matchesMenu && isMobileMenuOpen) {
            setMobileMoreAnchorEl(null)
        }
    }, [matchesMenu])

    return (
        <div className={matches ? "contentorIcon" : 'contentorHomePage'}>
            {
                matchesMenu ?
                    <>
                        <Tooltip title={"Menu"}>
                            <IconButton
                                style={{ cursor: 'pointer !important', color: 'var(--color-orange)' }}
                                size="large"
                                onClick={handleMobileMenuOpen}
                                disableRipple={true}
                            >
                                <MenuIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                        <Menu anchorEl={mobileMoreAnchorEl} open={isMobileMenuOpen} onClose={handleMobileMenuClose}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            {buttons.map((el, i) => (
                                <MenuItem key={i} disableRipple={true} onClick={() => el.label === "Logout" ? el.click() : onClickButton(el.click)}>
                                    <IconButton
                                        key={i}
                                        style={{
                                            cursor: 'pointer !important',
                                            color: (() => {
                                                if (el.label === "Reservas" && activeReservations) {
                                                    return 'var(--color-orange)'
                                                } else if (el.label === "Informações" && activeDescription) {
                                                    return 'var(--color-orange)'
                                                } else if (el.label === "Perfil" && activeProfile) {
                                                    return 'var(--color-orange)'
                                                } else {
                                                    return 'var(--color-grey-dark)'
                                                }
                                            })()
                                        }}
                                        sx={{ "&:hover": { color: 'var(--color-orange) !important' } }}
                                        size="large"
                                        disableRipple={true}
                                    >
                                        <el.src fontSize='medium' />
                                        <span style={{ marginLeft: '5px' }}>{el.label}</span>
                                    </IconButton>
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                    :
                    matches ?
                        <div>
                            {buttons.map((el, i) => (
                                <Tooltip title={el.label} key={i}>
                                    <IconButton
                                        key={i}
                                        style={{
                                            cursor: 'pointer !important',
                                            color: (() => {
                                                if (el.label === "Reservas" && activeReservations) {
                                                    return 'var(--color-orange)'
                                                } else if (el.label === "Informações" && activeDescription) {
                                                    return 'var(--color-orange)'
                                                } else if (el.label === "Perfil" && activeProfile) {
                                                    return 'var(--color-orange)'
                                                } else {
                                                    return 'var(--color-white)'
                                                }
                                            })()
                                        }}
                                        sx={{ "&:hover": { color: 'var(--color-orange) !important' } }}
                                        size="large"
                                        onClick={() => el.label === "Logout" ? el.click() : onClickButton(el.click)}
                                        disableRipple={true}
                                    >
                                        <el.src fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                            ))}
                        </div>
                        :
                        <div className='divButtons'>
                            <div>
                                <button onClick={() => {
                                    setActiveReservations(true)
                                    setActiveDescription(false)
                                    setActiveProfile(false)
                                }} className={activeReservations ? "buttonSelected" : "button"}>Reservas</button>
                                <button onClick={() => {
                                    setActiveReservations(false)
                                    setActiveDescription(true)
                                    setActiveProfile(false)
                                }} className={activeDescription ? "buttonSelected" : "button"}>Informações</button>
                            </div>
                            <div style={{ marginRight: '16px' }}>
                                <Tooltip title={"Perfil"}>
                                    <IconButton
                                        style={{ cursor: 'pointer !important', color: `${activeProfile ? 'var(--color-orange)' : 'var(--color-white)'}` }}
                                        size="large"
                                        onClick={() => {
                                            setActiveReservations(false)
                                            setActiveDescription(false)
                                            setActiveProfile(true)
                                        }}
                                        disableRipple={true}
                                        sx={{ "&:hover": { color: 'var(--color-orange) !important' } }}
                                    >
                                        <PersonIcon fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Terminar sessão"}>
                                    <IconButton
                                        style={{ cursor: 'pointer !important', color: 'var(--color-white)' }}
                                        size="large"
                                        onClick={() => handleLogout()}
                                        disableRipple={true}
                                        sx={{ "&:hover": { color: 'var(--color-orange) !important' } }}
                                    >
                                        <LoginIcon fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
            }
        </div >
    )
}