import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import LoadingContext from './components/utils/loading.context'
import HomePage from './pages/homePage'
import { Snackbar, AlertTitle } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import LoadingBar from './components/utils/loadingBar'
import './App.css'
import CancellationV2 from './components/Cancellations/cancellationV2'
import Page404 from './components/error404/error404'
import LoginPage from './pages/loginPage'
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import UserConfirmEmail from './components/login/userConfirmEmail'
import CrediCardSuccess from './components/ModalReservation/crediCardSuccess'
import CrediCardError from './components/ModalReservation/crediCardError'
import CrediCardCancel from './components/ModalReservation/crediCardCancel'
import ConfirmUser from './components/Profile/confirmUser'
import Rejectuser from './components/Profile/rejectUser'
import TermsAndConditions from './pages/termsAndConditions'

export default function App() {
  const [isLoad, setIsLoad] = useState(false)
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [colorSnack, setColorSnack] = useState("")
  const [alertFunction, setAlertFunction] = useState("")

  useEffect(() => {
    localStorage.setItem("lng", "pt")
    const timer = setTimeout(() => {
      if (isLoad === true) {
        setIsLoad(false)
        toastMessages('error', 'Load Timeout', 'ERROR')
      }
    }, 30000)
    return () => {
      clearTimeout(timer)
    }
  }, [isLoad])

  const toastMessages = (type, alert, string) => {
    setIsOpenToast(true)
    setColorSnack(type)
    setAlertMessage(alert)
    setAlertFunction(string)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setIsOpenToast(false)
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <LoadingContext.Provider value={{
      isLoad: isLoad,
      setIsLoad: setIsLoad,
      isOpenToast: isOpenToast,
      setIsOpenToast: setIsOpenToast,
      alertMessage: alertMessage,
      setAlertMessage: setAlertMessage,
      colorSnack: colorSnack,
      setColorSnack: setColorSnack,
      alertFunction: alertFunction,
      setAlertFunction: setAlertFunction,
      toastMessages: toastMessages,
      handleClose: handleClose
    }}>
      <div style={isLoad ?
        { position: 'absolute', height: '100vh', width: '100%', zIndex: '5000000', top: '0', backgroundColor: 'var(--color-grey-light)', opacity: '0.9', overflow: 'hidden' } :
        { position: 'absolute', height: '100%', width: '100%', display: 'none' }}
      >
        <LoadingBar />
      </div>
      <Router>
        <Routes>
          {
            localStorage.getItem("tokenMaeil") ?
              <Route path="/" element={<Navigate to="/home" />} />
              :
              <Route path="/" element={<Navigate to="/login" />} />
          }
          <Route
            path="*" element={<Page404 />} />
          <Route
            path="/home" element={<HomePage />} />
          <Route
            path="/login" element={<LoginPage />} />
          <Route
            path="/cancelarreserva" element={<CancellationV2 />} />
          <Route
            path="/confirmEmail" element={<UserConfirmEmail />} />
          <Route
            path="/creditCardSuccess" element={<CrediCardSuccess />} />
          <Route
            path="/creditCardError" element={<CrediCardError />} />
          <Route
            path="/creditCardCancel" element={<CrediCardCancel />} />
          <Route
            path="/confirmPartner" element={<ConfirmUser />} />
          <Route
            path="/rejectPartner" element={<Rejectuser />} />
          <Route
            path="/home/termsAndConditions" element={<TermsAndConditions />} />
          <Route
            path="/login/termsAndConditions" element={<TermsAndConditions />} />
        </Routes>
      </Router>
      <Snackbar disableWindowBlurListener={true} open={isOpenToast} autoHideDuration={2500} onClose={handleClose}>
        <Alert onClose={handleClose} severity={colorSnack} sx={{ width: '100%' }}>
          <AlertTitle style={{ textTransform: 'capitalize' }}>
            {colorSnack === "error" ? "Erro" : colorSnack === "success" ? "Sucesso" : colorSnack === "info" ? "Informação" : ""}
          </AlertTitle>
          {alertFunction}<b>{alertMessage}</b>
        </Alert>
      </Snackbar>
    </LoadingContext.Provider>
  )
}