import React, { useState } from 'react'
import MuiPhoneNumber from 'material-ui-phone-number'

export default function InputPhoneNumber({ onChange, value, label, helperText, error }) {

    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }
    return (
        <MuiPhoneNumber
            countryCodeEditable={false}
            sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--color-black-light) !important',
                    boxShadow: isFocused ? '2px 2px 2px var(--color-black-light) !important' : "none",
                },
                '& .MuiOutlinedInput-root': {
                    height: '40px'
                },
                '& label': { color: 'var(--color-black-light)' },
                '& label.Mui-focused': { color: 'var(--color-black-light)' },
            }}
            InputLabelProps={{ autoComplete: "none", shrink: true }}
            label={label}
            required={true}
            onFocus={handleFocus}
            onBlur={handleBlur}
            defaultCountry={'pt'}
            variant="outlined"
            onChange={onChange}
            value={value}
            // regions={'europe'}
            onlyCountries={['pt']}
            helperText={helperText}
            error={error}
        />
    )
}