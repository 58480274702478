import React, { useState } from 'react'
import { IconButton, Toolbar, Box, AppBar, Menu, Tooltip, MenuItem, Paper } from "@mui/material"
import './headerLogin.css'
import M_Button from '../utils/M_Button/M_Button'
import { useTranslation } from "react-i18next"

export default function HeaderLogin() {
    const { t } = useTranslation()
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const companyButtons = [
        {
            label: "Facebook",
            link: "https://www.facebook.com/devlop.systems",
            src: "/icons/socialIcons/Facebook.svg"
        },
        {
            label: "Instagram",
            link: "https://www.instagram.com/devlop.systems/",
            src: "/icons/socialIcons/Instagram.svg"
        },
        {
            label: "Linkedin",
            link: "https://www.linkedin.com/company/devlop-systems/mycompany/",
            src: "/icons/socialIcons/Linkedin.svg"
        },
        {
            label: "Youtube",
            link: "https://www.youtube.com/@devlopsystems",
            src: "/icons/socialIcons/Youtube.svg"
        },
    ]

    const onClickButton = (url) => {
        window.open(url, '_blank')
    }

    const mobileMenuId = "primary-search-account-menu-mobile"
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {companyButtons.map((el, i) => (
                <MenuItem disableRipple={true} onClick={() => onClickButton(el.link)} className="HeaderMenuItem" key={i}>
                    <IconButton className='HeaderIconButton' disableRipple={true}>
                        <Paper className='HeaderPaper'>
                            <img src={el.src} alt={el.label} className='headerImgs' />
                            <span className='HeaderTextMenu HeaderTextInherit'>{el.label}</span>
                        </Paper>
                    </IconButton>
                </MenuItem>
            ))}
        </Menu>
    )

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" className='appBar'>
                <Toolbar>
                    {/* <IconButton className='iconButtonBarLogo' disableRipple={true}>
                        <Paper className='paperBarLogo'>
                            <img src="/Images/logo.png" alt="logo" style={{ maxHeight: '50px' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                                <span style={{ color: 'var(--color-black)', fontSize: '10px', width: '103px' }}>CLUB INTERNACINAL</span>
                                <span style={{ color: 'var(--color-black)', fontSize: '10px', width: '103px' }}>DE FOOT-BALL</span>
                            </div>
                        </Paper>
                    </IconButton> */}
                    {/* <Box sx={{ flexGrow: 1 }} /> */}
                    {/* <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        {companyButtons.map((el, i) => (
                            <div key={i}>
                                <M_Button
                                    backgroundColor={"transparent"}
                                    tooltip={el.label}
                                    icon={<img src={el.src} alt={el.label} className={el.className} />}
                                    onClick={() => onClickButton(el.link)}
                                />
                            </div>
                        ))}
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            disableRipple={true}
                        >
                            <Tooltip title={"Open menu"}>
                                <img src="/icons/menu3Bars.svg" alt="options" className='headerImgs' />
                            </Tooltip>
                        </IconButton>
                    </Box> */}
                </Toolbar>
            </AppBar>
        </Box>
    )
}