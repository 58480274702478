import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BufferService } from '../utils/buffer.service'
import '../Cancellations/cancellations.css'
import LoadingContext from '../utils/loading.context'

export default function CrediCardError() {
    const ctx = useContext(LoadingContext)
    const navigate = useNavigate()
    const location = useLocation()
    const BackendPut = new BufferService('payments/CreditCard')
    const urlParam = decodeURIComponent(location.search.replace("?", ""))

    useEffect(() => {
        // console.log(urlParam)

        const regex = /([^&=]+)=([^&]*)/g
        let match
        const params = {}

        while ((match = regex.exec(urlParam)) !== null) {
            params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
        }

        let tempValues = {
            'Type': "error",
            'Amount': params['amount'] || "",
            'Id': params['id'] || "",
            'RequestId': params['requestId'] || "",
        }

        // console.log("tempValues", tempValues)

        BackendPut.put(tempValues).then(post => {
            if (post.name === 'AxiosError') {
                ctx.toastMessages('error', '', "Algo correu mal, tente novamente!")
                return
            }
        })
    }, [])

    return (
        <>
            <div style={{ marginTop: '25px' }} className='spanNoteCancelV2'>
                <h1 style={{ textTransform: 'inherit', color: "orange" }}>Erro na transação</h1>
            </div>
            <div style={{ marginTop: '25px' }} className='spanNoteCancelV2'>
                <span style={{ fontSize: '20px', textTransform: 'inherit' }}>Ocorreu um erro no processamento da transação e a reserva irá ser cancelada. Tente novamente mais tarde!</span>
            </div>
            <div className='divCancelButtonV2'>
                <button onClick={() => navigate("/")} className='buttonCancelV2'>
                    Voltar
                </button>
            </div>
        </>
    )
}