import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BufferService } from '../utils/buffer.service'
import LoadingContext from '../utils/loading.context'
import './cancellations.css'

export default function CancellationV2() {
    const ctx = useContext(LoadingContext)
    const navigate = useNavigate()
    const location = useLocation()
    const cancellationBuffer = new BufferService('cancellation')

    const handleSubmit = (e) => {
        const urlParam = decodeURIComponent(location.search.replace("?url=", ""))
        ctx.setIsLoad(true)
        e.preventDefault()

        cancellationBuffer.delete(`?value=${urlParam}`).then(post => {
            if (post.name === 'AxiosError') {
                ctx.setIsLoad(false)
                ctx.toastMessages('error', '', "Algo correu mal, tente novamente!")
                return
            }
            ctx.setIsLoad(false)
            ctx.toastMessages('success', "Cancelamento realizado com sucesso!")
            setTimeout(function () {
                navigate('/')
            }, 2000)
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ marginTop: '25px' }} className='spanNoteCancelV2'>
                <span style={{ fontSize: '20px', textTransform: 'inherit' }}>Tem a certeza que quer cancelar a reserva ?</span>
            </div>
            <div className='divCancelButtonV2'>
                <button type="submit" className='buttonCancelV2'>
                    Cancelar Reserva
                </button>
            </div>
            <div className='spanNoteCancelV2'>
                <span style={{ fontSize: '14px', textTransform: 'inherit' }}>*Nota: Será informado via email pelo CIF da confirmação do cancelamento da reserva</span>
            </div>
        </form>
    )
}