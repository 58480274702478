import React, { useContext, useState } from 'react'
import M_InputTextField from '../utils/M_InputTextField/M_InputTextField'
import M_RadioButton from '../utils/M_RadioButton/M_RadioButton'
import M_IconButton from '../utils/M_IconButton/M_IconButton'
import CloseIcon from '@mui/icons-material/Close'
import UndoIcon from '@mui/icons-material/Undo'
import LoadingContext from '../utils/loading.context'
import { BufferService } from '../utils/buffer.service'
import './modalReservation.css'
import axios from "axios"
import { defaultURl } from '../utils/url.service'
import ReCAPTCHA from "react-google-recaptcha"
import InputPhoneNumber from '../utils/InputPhoneNumber'

export default function SecondPage({ phoneNumberValid, handlePhoneNumberChange, setFirstPage, inputsModal,
    submitReservation, setOpenModal, handleRadioChange, userSelectDic, setCurrentWeek,
    mbway, crediCard, setMbway, setCrediCard }) {

    const ctx = useContext(LoadingContext)
    const backendData = new BufferService('reservation')
    const [verified, setVerified] = useState(false)
    const apiKey = process.env.REACT_APP_SITE_KEY

    const handleSubmit = (e) => {
        ctx.setIsLoad(true)
        e.preventDefault()

        let tempValues = {
            'Datetime': userSelectDic.get("Datetime"),
            'Day': userSelectDic.get("Day"),
            'DayWeek': userSelectDic.get("DayWeek"),
            'Month': userSelectDic.get("Month"),
            'Year': userSelectDic.get("Year"),
            'CourtName': userSelectDic.get("CourtName"),
            'AircourtsId': userSelectDic.get("AircourtsId"),
            'CourtDetails': "",
            'ReservationTime': userSelectDic.get("ReservationTime").toString(),
            'Duration': userSelectDic.get("Duration").replace("mins", "").trim(),
            'LadderTournament': (userSelectDic.get("LadderTournament") === "sim") ? true : false,
            'Name': userSelectDic.get("Name"),
            'Surname': userSelectDic.get("Surname"),
            'Email': userSelectDic.get("Email"),
            'Phone': userSelectDic.get("Phone").substring(4),
            'Partner': (userSelectDic.get("Partner") === 'sim') ? true : false,
            'Player2': userSelectDic.get("Player2"),
            'Player3': (userSelectDic.get("TypeGame") === 'Single' ? "" : userSelectDic.get("Player3")),
            'Player4': (userSelectDic.get("TypeGame") === 'Single' ? "" : userSelectDic.get("Player4")),
            'PaymentType': mbway ? 2 : crediCard ? 1 : 0,
        }

        backendData.post(tempValues).then(resPost => {
            if (resPost.name === 'AxiosError') {
                ctx.setIsLoad(false)
                ctx.toastMessages('error', '', resPost.response.data ? resPost.response.data : "Algo correu mal, tente novamente!")
                setTimeout(function () {
                    window.location.reload()
                }, 4000)
                return
            }
            axios.get(`${defaultURl}availability/${userSelectDic.get("Datetime")}`)
                .then(function (response) {
                    setCurrentWeek(response.data.Reservation)
                    setOpenModal(false)
                    ctx.setIsLoad(false)
                    if (crediCard && userSelectDic.get("Partner") === 'nao') {
                        ctx.toastMessages('success', "A pré-reserva foi realizada com sucesso, será enviado para o pagamento!")
                        setTimeout(function () {
                            window.location.href = resPost.Link
                        }, 4000)
                    }
                    else if (userSelectDic.get("Partner") === 'nao' && mbway) {
                        ctx.toastMessages('success', "A pré-reserva foi realizada com sucesso, em email terá os proximos passos!")
                        setTimeout(function () {
                            window.location.reload()
                        }, 4000)
                    } else {
                        ctx.toastMessages('success', "Reserva efetuada com sucesso!")
                        setTimeout(function () {
                            window.location.reload()
                        }, 4000)
                    }
                })
                .catch(function (error) {
                    ctx.setIsLoad(false)
                    ctx.toastMessages('error', '', "Algo correu mal, tente novamente!")
                    setTimeout(function () {
                        window.location.reload()
                    }, 4000)
                    return error
                })
        })
    }

    const renderInput = (value) => {
        if (value.type === "text") {
            return (
                <M_InputTextField
                    required={value.code === "Player2" || value.code === "Player3" || value.code === "Player4"}
                    label={value.label}
                    type={"text"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    onChange={(e) => submitReservation(e, value.code)}
                    value={userSelectDic.get(value.code) || ""}
                    disabled={value.code === "Name" || value.code === "Surname"}
                />
            )
        } else if (value.type === "number") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"number"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    maxNumbers={13}
                    onChange={(e) => { submitReservation(e, value.code) }}
                    value={userSelectDic.get(value.code) || ""}
                />
            )
        } else if (value.type === "email") {
            return (
                <M_InputTextField
                    disabled
                    label={value.label}
                    type={"email"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    value={userSelectDic.get(value.code) || ""}
                    onChange={(e) => submitReservation(e, value.code)}
                />
            )
        } else if (value.type === "radio") {
            return (
                <M_RadioButton
                    label={value.label}
                    options={value.options}
                    selectedValue={value.code === "TypeGame" ? userSelectDic.get("TypeGame") : userSelectDic.get(value.code) || ""}
                    onChange={(e) => {
                        submitReservation(e, value.code)
                        handleRadioChange(e, value.code)
                    }}
                    required={value.code === "TypeGame"}
                    disabled={(value.code === "TypeGame" && userSelectDic.get("Duration") === "60 mins") || value.code === "Partner"}
                    tooltipName={
                        value.code === "TypeGame" && (userSelectDic.get("TypeGame") === 'Single' && userSelectDic.get("Duration") === "60 mins") ?
                            "Reservas de 60 minutos são exclusivas para singulares" : ""}
                />
            )
        } else if (value.type === "tel") {
            return (
                <InputPhoneNumber
                    label={value.label}
                    onChange={(e) => {
                        submitReservation(e, value.code)
                        handlePhoneNumberChange(e)
                    }}
                    value={userSelectDic.get(value.code) || ""}
                    error={!phoneNumberValid}
                    helperText={!phoneNumberValid ? "Número de telemóvel inválido" : "Número de telemóvel válido"}
                />
            )
        } else {
            return null
        }
    }

    const reCaptchaButton = (token) => {
        setVerified(true)
    }

    const CallBackReCaptchaButton = (token) => {
        setVerified(false)
        localStorage.removeItem("_grecaptcha")
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='closeIconSticky'>
                <div className='buttonClose'>
                    <M_IconButton icon={<CloseIcon />} fontSize={'1'} onClick={() => {
                        setOpenModal(false)
                    }} />
                </div>
                <div className='buttonUndo'>
                    <M_IconButton icon={<UndoIcon />} fontSize={'1'} onClick={() => {
                        setFirstPage(true)
                    }} />
                </div>
            </div>
            <div className='modalPrincipalDiv'>
                <div className='modalLeftDiv SecondPageLeft'>
                    <div className='modalTitle'> Confirmação da reserva </div>
                    <div className='userDataFields2'>
                        <div className='UserDataFieldsPadding'> {userSelectDic.get("CourtName")}</div>
                        <div className='UserDataFieldsPadding'> {userSelectDic.get("DayWeek").substring(0, 3)}, {userSelectDic.get("Day")} {userSelectDic.get("Month")} {userSelectDic.get("Year")} </div>
                        <div className='UserDataFieldsPadding'> {userSelectDic.get("ReservationTime")} horas</div>
                        <div className='UserDataFieldsPadding'> {userSelectDic.get("Duration")}</div>
                        {userSelectDic.get("Partner") === 'nao' ?
                            <div className='rowFirstPage'>
                                <div className='UserDataFieldsPadding fontWeightBold'> Subtotal: </div>
                                <div className='UserDataFieldsPadding fontWeightBold'> {userSelectDic.get("CourtPrice")}€ </div>
                            </div> : <></>}
                    </div>
                    <div className='modalInputFields'>
                        <div className='left-column'>
                            <div className='singleInputsSecondPage'>
                                {renderInput(inputsModal[8])}
                            </div>
                            <div className='singleInputsSecondPage'>
                                {renderInput(inputsModal[0])}
                            </div>
                            <div className='singleInputsSecondPage'>
                                {renderInput(inputsModal[2])}
                            </div>
                            <div className='singleInputsSecondPage'>
                                {renderInput(inputsModal[4])}
                            </div>
                            <div className='singleInputsSecondPage'>
                                {renderInput(inputsModal[6])}
                            </div>
                        </div>
                        <div className='right-column'>
                            <div className='singleInputsSecondPage'>
                                {renderInput(inputsModal[1])}
                            </div>
                            <div className='singleInputsSecondPage'>
                                {renderInput(inputsModal[3])}
                            </div>
                            <div className='singleInputsSecondPage'>
                                {userSelectDic.get("TypeGame") === 'Single' ? "" : renderInput(inputsModal[5])}
                            </div>
                            <div className='singleInputsSecondPage'>
                                {userSelectDic.get("TypeGame") === 'Single' ? "" : renderInput(inputsModal[7])}
                            </div>
                            <div className='ReCAPTCHADiv'>
                                <ReCAPTCHA
                                    sitekey={apiKey}
                                    onChange={reCaptchaButton}
                                    onExpired={CallBackReCaptchaButton}
                                    style={{ transform: 'scale(0.77)', transformOrigin: '0 0' }}
                                    badge={'bottomright'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='divModalSubmitButton'>
                        {userSelectDic.get("Partner") === 'nao' ?
                            <div className='payButtons'>
                                <div className='mbwayButton'>
                                    <button className={'buttonConfirm'} onClick={() => {
                                        setMbway(true)
                                        setCrediCard(false)
                                    }}>
                                        MB WAY
                                    </button>
                                </div>
                                <div className='ccButton'>
                                    <button className={'buttonConfirm'} onClick={() => {
                                        setMbway(false)
                                        setCrediCard(true)
                                    }}>
                                        Cartão de crédito
                                    </button>
                                </div>
                            </div>
                            :
                            <button type="submit"
                                disabled={!phoneNumberValid || !verified}
                                className={`${!phoneNumberValid || !verified ? 'buttonConfirmDisabled' : 'buttonConfirm'}`}
                            >
                                Confirmar reserva
                            </button>
                        }
                    </div>
                    <div className='spanNote'>
                        <span style={{ fontSize: '13px', textTransform: 'inherit' }}>Nota: Será informado via email pelo CIF da confirmação da reserva</span>
                    </div>
                </div>
                <div className='modalRightDivFirst'>
                    <img src="/Images/ModalImage.png" alt="modalImage" className='imgShadow' />
                </div>
            </div>
        </form>
    )
}