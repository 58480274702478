import React from 'react'
import './M_Button.css'
import { Tooltip } from '@mui/material'

export default function M_Button({
    onClick, borderColor, backgroundColor, label, icon, labelColor, iconPosition, smallIcon,
    largeIcon, bold, hoverEffect, tooltip, id, height, borderRadius,
    normalButton, largeButton, width, removePadding, marginLeft, marginRight, marginTop, marginBottom, type, disabled
}) {

    const divImg = (icon && <i style={{ marginLeft: '5px', marginRight: '5px', scale: `${smallIcon ? '.5' : largeIcon ? '1.5' : '1'}` }}> {icon} </i>)

    const clickFunction = (event) => {
        onClick(event)
    }

    const styledButtons = {
        padding: `${normalButton ? '0px 5px' : largeButton ? '5px 15px' : removePadding ? '0px 0px' : width ? `0px ${width}px` : '0px 5px'}`,
        border: `${borderColor ? `1px solid ${borderColor}` : '0px'}`,
        backgroundColor: backgroundColor ? backgroundColor : "var(--color-white)",
        color: labelColor ? labelColor : "var(--color-black)",
        fontWeight: bold ? "bold" : "",
        height: height ? `${height}px` : "",
        borderRadius: borderRadius ? `${borderRadius}px` : "0px",
        marginLeft: marginLeft ? `${marginLeft}px` : "0px",
        marginRight: marginRight ? `${marginRight}px` : "0px",
        marginTop: marginTop ? `${marginTop}px` : "0px",
        marginBottom: marginBottom ? `${marginBottom}px` : "0px",
    }

    const disableStyledButtons = {
        padding: `${normalButton ? '0px 5px' : largeButton ? '5px 15px' : removePadding ? '0px 0px' : width ? `0px ${width}px` : '0px 5px'}`,
        border: `1px solid var(--color-grey-light)`,
        backgroundColor: "var(--color-grey-light)",
        color: "var(--color-white)",
        fontWeight: "bold",
        height: height ? `${height}px` : "",
        borderRadius: borderRadius ? `${borderRadius}px` : "0px",
        marginLeft: marginLeft ? `${marginLeft}px` : "0px",
        marginRight: marginRight ? `${marginRight}px` : "0px",
        marginTop: marginTop ? `${marginTop}px` : "0px",
        marginBottom: marginBottom ? `${marginBottom}px` : "0px",
    }

    return (
        <div draggable={false} onDragStart={(event) => event.preventDefault()} id={id}>
            {tooltip ?
                hoverEffect ?
                    <Tooltip onClick={clickFunction} title={tooltip}>
                        <button disabled={disabled} type={type} style={disabled ? disableStyledButtons : styledButtons} className={`${disabled ? "buttonClass2" : "buttonClass hoverEffect"}`} >
                            {!iconPosition || iconPosition.toUpperCase() === 'LEFT' ? divImg : ''}
                            <span className='fontSizeLabel'>{label}</span>
                            {iconPosition && iconPosition.toUpperCase() === 'RIGHT' && divImg}
                        </button>
                    </Tooltip>
                    :
                    <Tooltip onClick={clickFunction} title={tooltip}>
                        <button disabled={disabled} type={type} style={disabled ? disableStyledButtons : styledButtons} className={`${disabled ? "buttonClass2" : "buttonClass"}`}>
                            {!iconPosition || iconPosition.toUpperCase() === 'LEFT' ? divImg : ''}
                            <span className='fontSizeLabel'>{label}</span>
                            {iconPosition && iconPosition.toUpperCase() === 'RIGHT' && divImg}
                        </button>
                    </Tooltip>
                :
                hoverEffect ?
                    <button disabled={disabled} type={type} onClick={clickFunction} style={disabled ? disableStyledButtons : styledButtons} className={`${disabled ? "buttonClass2" : "buttonClass hoverEffect"}`}>
                        {!iconPosition || iconPosition.toUpperCase() === 'LEFT' ? divImg : ''}
                        <span className='fontSizeLabel'>{label}</span>
                        {iconPosition && iconPosition.toUpperCase() === 'RIGHT' && divImg}
                    </button>
                    :
                    <button disabled={disabled} type={type} onClick={clickFunction} style={disabled ? disableStyledButtons : styledButtons} className={`${disabled ? "buttonClass2" : "buttonClass"}`}>
                        {!iconPosition || iconPosition.toUpperCase() === 'LEFT' ? divImg : ''}
                        <span className='fontSizeLabel'>{label}</span>
                        {iconPosition && iconPosition.toUpperCase() === 'RIGHT' && divImg}
                    </button>
            }
        </div >
    )
}