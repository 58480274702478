import React from 'react'
import './M_IconButton.css'

export default function M_IconButton({ onClick, icon, fontSize }) {

    return (
        <div className='M_IconButton' style={{ scale: fontSize ? fontSize : '1' }} onClick={onClick}>
            {icon}
        </div>
    )
}