import React, { useEffect, useContext, useState } from 'react'
import LoadingContext from 'src/components/utils/loading.context'
import HomePageTabs from 'src/components/homePageTabs/homePageTabs'
import { useTranslation } from "react-i18next"
import './pages.css'
import Description from 'src/components/Description/description'
import Reservations from 'src/components/Reservations/reservations'
import Profile from 'src/components/Profile/profile'

export default function HomePage() {
    const { t, i18n } = useTranslation()
    const ctx = useContext(LoadingContext)

    const [activeReservations, setActiveReservations] = useState(true)
    const [activeDescription, setActiveDescription] = useState(false)
    const [activeProfile, setActiveProfile] = useState(false)

    const handleChangeLng = (lng) => {
        i18n.changeLanguage(lng)
        localStorage.setItem("lng", lng)
    }

    useEffect(() => {
        ctx.setIsLoad(true)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className='headerPage'>
                <div className='divlogoCif'>
                    <div className='subDivLogoCif'>
                        <img src="/Images/logo.png" alt="logo" className='imgCifStyles' />
                        <div className='spansDivLogosCif'>
                            <span style={{ color: 'var(--color-black)', fontSize: '10px', width: '103px' }}>CLUB INTERNACINAL</span>
                            <span style={{ color: 'var(--color-black)', fontSize: '10px', width: '103px' }}>DE FOOT-BALL</span>
                        </div>
                    </div>
                </div>
                <div className='subDivMenu'>
                    {/* <div style={{ position: 'absolute', display: 'flex', alignContentc: 'right' }}>
                    <img style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleChangeLng("en")} src='/icons/flagIcons/EN.png' alt="EN" width="40px" />
                    <img style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleChangeLng("es")} src='/icons/flagIcons/ES.png' alt="ES" width="40px" />
                    <img style={{ cursor: 'pointer' }} onClick={() => handleChangeLng("pt")} src='/icons/flagIcons/PT.png' alt="PT" width="40px" />
                </div> */}
                    <HomePageTabs t={t}
                        setActiveReservations={setActiveReservations}
                        setActiveDescription={setActiveDescription}
                        setActiveProfile={setActiveProfile}
                        activeReservations={activeReservations}
                        activeDescription={activeDescription}
                        activeProfile={activeProfile}
                    />
                </div>
            </div>
            <div>
                {activeReservations ? <Reservations /> :
                    activeDescription ? <Description /> :
                        activeProfile ? <Profile /> : <></>
                }
            </div>
        </>
    )
}