import React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Tooltip } from "@mui/material"

export default function M_RadioButton({ label, options, selectedValue, onChange, required, disabled, tooltipName }) {
    return (
        <FormControl>
            <FormLabel style={{ color: 'var(--color-black)' }} id="radio-buttons">{label}</FormLabel>
            <RadioGroup
                aria-labelledby="radio-buttons"
                value={selectedValue}
                onChange={onChange}
            >
                <div style={{ display: 'flex' }}>
                    {options.map((option, key) => (
                        <Tooltip key={key} title={tooltipName}>
                            <FormControlLabel
                                key={key}
                                required={required}
                                value={option.value}
                                control={
                                    <Radio disableRipple={true}
                                        sx={{
                                            '&, &.Mui-checked': {
                                                color: 'var(--color-black-light)',
                                            },
                                            "&.Mui-focusVisible": {
                                                color: "var(--color-black)",
                                                backgroundColor: "var(--color-grey-light)"
                                            },
                                        }}
                                    />
                                }
                                label={option.label}
                                disabled={disabled}
                            />
                        </Tooltip>
                    ))}
                </div>
            </RadioGroup>
        </FormControl>
    )
}