import React from 'react'
import { BufferService } from '../utils/buffer.service'
import M_Button from '../utils/M_Button/M_Button'

export default function EditPassword({ ctx, inputs, renderInput, userAccount, setUserAccount }) {
    const backendPut = new BufferService('identity/updatepass')
    const backendGet = new BufferService('identity/userrole')

    const backendPostPassword = (e) => {
        ctx.setIsLoad(true)
        e.preventDefault()

        backendGet.get().then(resPost => {
            if (resPost.name === 'AxiosError') {
                ctx.setIsLoad(false)
                ctx.toastMessages('error', '', "Algo correu mal, tente novamente!")
                return
            }
            let tempValues = {
                'OldPassword': resPost.User.Password || "",
                'NewPassword': resPost.User.Password || ""
            }
            Object.keys(tempValues).forEach(key => {
                userAccount.set(key, tempValues[key])
            })
            setUserAccount(new Map(userAccount))
        })

        let tempValues = {
            'OldPassword': userAccount.get("OldPassword"),
            'NewPassword': userAccount.get("NewPassword"),
        }

        if ((tempValues.OldPassword === tempValues.NewPassword) || (tempValues.OldPassword === "" || tempValues.NewPassword === "")) {
            ctx.setIsLoad(false)
            ctx.toastMessages("error", "", "Algo correu mal, tente novamente!")
        } else {
            backendPut.put(tempValues).then(response => {
                if (response.name === 'AxiosError') {
                    ctx.setIsLoad(false)
                    ctx.toastMessages("error", "", "Algo correu mal, tente novamente!")
                    return
                }
                ctx.setIsLoad(false)
                ctx.toastMessages("success", "", "Password alterada com sucesso!")
            })
        }
    }

    return (
        <form onSubmit={backendPostPassword} style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <div className='modalInputFieldsProfile'>
                    <div className='left-columnProfile'>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[5])}
                        </div>
                    </div>
                    <div className='right-columnProfile'>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[8])}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '68.81px', display: 'flex', marginTop: '15px', marginTop: '15px', borderTop: '1px solid var(--color-grey)', alignItems: 'center', justifyContent: 'center' }}>
                <M_Button
                    height={40}
                    width={10}
                    bold
                    label={"Confirmar"}
                    labelColor={'var(--color-white)'}
                    type={"submit"}
                    onClick={() => { }}
                    borderRadius={15}
                    hoverEffect
                    backgroundColor={"var(--color-green)"}
                    borderColor={"var(--color-green)"}
                />
            </div>
        </form>
    )
}