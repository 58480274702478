import React from 'react'
import { Image } from 'primereact/image'
import './description.css'
import { Divider } from '@mui/material'

export default function Description() {
    return (
        <div className='contentorDescription'>
            <div className='secoundDiv'>
                <div className='leftDiv' >
                    <h2 className='DestextTransfUpper'>Regras para reserva de campos de ténis</h2>
                    <Divider />
                    <h4 className='DestextTransfUpper' style={{ color: 'var(--color-orange)' }}>Pode realizar reservas e cancelamentos dos campos de forma simples e rápida. Aqui estão algumas dicas importantes:</h4>
                    <ol>
                        <li className='DesMargin10'><b>Reservas:</b> Pode selecionar apenas um dia, um campo e uma hora na mesma reserva. Não é possível reservar, por exemplo, Campo 1 e Campo 2 para o mesmo dia e hora. Tem ainda a escolha de períodos de 60 minutos ou 120 minutos, ou apenas a opção de 60 minutos.</li>
                        <li className='DesMargin10'><b>Múltiplas Reservas:</b> Pode fazer várias reservas no mesmo dia ou para diferentes dias, mas não num único processo. Se desejar fazer duas ou três reservas, terá de iniciar um novo processo de reserva em cada ocasião.</li>
                        <li className='DesMargin10'><b>Jogadores:</b> Para reservas individuais, identifique com quem vai jogar, inserindo o nome da pessoa. Se for uma reserva multiplayer, indique os nomes dos jogadores adicionais.</li>
                        <li className='DesMargin10'><b>Flexibilidade de Reservas:</b> Pode fazer várias reservas no mesmo dia, reservando diferentes campos e horários. Por exemplo, reserve o Campo 1 para uma parte do dia e o Campo 2 para outro. As reservas ocorrem de hora em hora, e podem ser feitas para o mesmo dia ou dias diferentes.</li>
                        <li className='DesMargin10'><b>Política de Cancelamento:</b> <b style={{ textDecoration: 'underline' }}>Não é possível cancelar uma reserva com menos de 24 horas de antecedência.</b> Após efetuar uma reserva, receberá um e-mail de confirmação com todos os detalhes, incluindo um botão de cancelamento. Ao clicar nesse botão, o cancelamento será confirmado automaticamente, e receberá um e-mail adicional de confirmação do cancelamento.</li>
                        <li className='DesMargin10'><b>Exceção ao Regulamento:</b> Haverá uma exceção ao regulamento sendo que qualquer pessoa, seja sócio ou não sócio, com pacote ou sem, poderá efetuar reservas online, mediante a disponibilidade apresentada, desde que seja com, no mínimo, duas horas de antecedência.</li>
                        <li className='DesMargin10'><b>Condições:</b> A utilização do campo reservado pressupõe o <b style={{ textDecoration: 'underline' }}>pagamento completo e antecipado na portaria</b>, pelo que deverá chegar com a antecedência necessária para assegurar este pagamento. </li>
                    </ol>
                    <p>Agora é mais fácil do que nunca reservar o seu campo de ténis. Marque o seu jogo e aproveite o melhor do ténis connosco!</p>
                    <p>Para obter mais informações sobre as condições das reservas, os preços, os campos e até as instalações dos balneários, consulte o regulamento <a href="https://www.cif.org.pt/pdf/regulamento-para-utilizacao-dos-campos-de-tenis.pdf" target="_blank" rel="noreferrer">aqui.</a></p>
                </div>
                <div className='rightDiv'>
                    <div style={{ display: 'flex', justifyContent: 'left', padding: '10px' }}>
                        <Image src="/Images/courts/Central - 01.jpg" alt="Image 1" width={"250px"} height={"150px"} preview />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', marginTop: '-50px' }} >
                        <Image src="/Images/courts/Court Rapido - 01.jpg" alt="Image 2" width={"250px"} height={"150px"} preview />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', padding: '10px', marginTop: '-50px' }}>
                        <Image src="/Images/courts/Court Coberto - 03.jpg" alt="Image 3" width={"250px"} height={"150px"} preview />
                    </div>
                </div>
            </div>
        </div>
    )
}