import React from 'react'
import HeaderLogin from 'src/components/headerLogin/headerLogin'
import Login from 'src/components/login/login'
import { useTranslation } from "react-i18next"
import M_Button from 'src/components/utils/M_Button/M_Button'
import { useNavigate } from 'react-router-dom'

export default function LoginPage() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const redirectToExternalPage = () => {
        window.open('https://www.livroreclamacoes.pt/Inicio/', '_blank')
    }

    return (
        <>
            <HeaderLogin i18n={i18n} t={t} />
            <Login t={t} />
            <div style={{ position: 'absolute', bottom: '0', width: '100%', marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <M_Button
                        onClick={() => redirectToExternalPage()}
                        bold
                        backgroundColor={'var(--color-grey-light)'}
                        label={"Livro de reclamações"}
                    />
                </div>
                <div>|</div>
                <div style={{ marginRight: '16px', display: 'flex', justifyContent: 'flex-end' }}>
                    <M_Button
                        onClick={() => navigate('termsAndConditions')}
                        bold
                        backgroundColor={'var(--color-grey-light)'}
                        label={"Termos e condições"}
                    />
                </div>
            </div>
        </>
    )
}