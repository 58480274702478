import React from 'react'

const LoadingContext = React.createContext({
    isLoad: false,
    setIsLoad: () => { },
    isOpenToast: false,
    setIsOpenToast: () => { },
    alertMessage: '',
    setAlertMessage: () => { },
    colorSnack: '',
    setColorSnack: () => { },
    alertFunction: '',
    setAlertFunction: () => { },
    toastMessages: () => { },
    handleClose: () => { }
})

export default LoadingContext