import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from "./locales/en/en.json"
import pt from "./locales/pt/pt.json"
import es from "./locales/es/es.json"

export default i18next.use(initReactI18next).init({
    resources: {
        pt: {
            translation: pt,
        },
        en: {
            translation: en,
        },
        es: {
            translation: es,
        },
    },
    lng: localStorage.getItem("lng") || "en",
})