import React from 'react'
import './error404.css'
import Button from '@mui/material/Button'
import { useTranslation } from "react-i18next"

export default function Error404() {
    const { t } = useTranslation()
    return (
        <div className='container'>
            <div className='text404'>
                <div>
                    404
                </div>
            </div>
            <div className='textMedium'>
                {t("NotFoundPage")}
            </div>
            <div className='textSmall'>
                {t("CantFindPage")}
            </div>
            <div className='buttonRedirect'>
                <Button
                    variant="contained"
                    className='buttonBackHome'
                    href='/'>
                    {t("Back")}
                </Button>
            </div>
        </div>
    )
}