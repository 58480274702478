import React, { useState, useContext } from 'react'
import './login.css'
import { BufferService } from '../utils/buffer.service'
import M_InputTextField from '../utils/M_InputTextField/M_InputTextField'
import LoadingContext from '../utils/loading.context'
import M_Button from '../utils/M_Button/M_Button'
import { useNavigate } from 'react-router-dom'
import ModalRegister from './modalRegister'
import EmailIcon from '@mui/icons-material/Email'
import KeyIcon from '@mui/icons-material/Key'
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { IconButton } from '@mui/material'
import ModalChange from './modalChange'
import ReCAPTCHA from "react-google-recaptcha"

export default function Login({ t }) {
  const [openModalRegister, setOpenModalRegister] = useState(false)
  const [openModalPassword, setOpenModalPassword] = useState(false)
  const ctx = useContext(LoadingContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const bufferService = new BufferService('identity/login')
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const [verified, setVerified] = useState(false)
  const apiKey = process.env.REACT_APP_SITE_KEY
  const reCaptchaButton = (token) => {
    setVerified(true)
  }

  const CallBackReCaptchaButton = (token) => {
    setVerified(false)
    localStorage.removeItem("_grecaptcha")
  }

  const handleSubmit = () => {
    // GET TOKEN FOR AUTH
    const user = {
      "email": email,
      "password": password
    }
    bufferService.post(user).then(response => {
      // console.log("response", response)
      if (response.name === 'AxiosError') {
        ctx.toastMessages("error", "",
          response.request.responseText === "{\"Errors\":[\"User does not exist\"]}" ? "Este email não está registado!" :
            response.request.responseText === "{\"Errors\":[\"User/password combination is wrong\"]}" ? "A combinação do email com a password está errada!" :
              "Algo correu mal, tente novamente!")
        return
      }
      if (response.Token === null) {
        ctx.toastMessages("success", "", "Precisa de confirmar o email!")
      }
      else {
        localStorage.setItem('tokenMaeil', response.Token)
        ctx.toastMessages("success", "", t("AlertMessageSucessLogged"))
        navigate(`/home`)
      }
    })
  }

  const submitEnter = (e) => {
    if (e.key === 'Enter') {
      !verified ? <></> :
        handleSubmit(e)
    }
  }

  return (
    <div className='principal'>
      <div className='boxForm'>
        <div className='divimage'>
          <img style={{ width: 'auto', height: '190px' }} src='/images/cif-logo.png' className='image' alt='CIFLOGO' />
        </div>
        <div className='divider'></div>
        <div className='form'>
          <div>
            <M_InputTextField
              iconStart={<EmailIcon style={{ marginRight: '10px', color: 'var(--color-grey)' }} />}
              secondColor={"var(--color-black-light)"}
              color={"var(--color-orange)"}
              label={t("textFieldEmailLabel")}
              type={"text"}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={(e) => submitEnter(e)}
            />
            <M_InputTextField
              iconStart={<KeyIcon style={{ marginRight: '10px', color: 'var(--color-grey)' }} />}
              secondColor={"var(--color-black-light)"}
              color={"var(--color-orange)"}
              label={t("textFieldPasswordLabel")}
              type={showPassword ? "text" : "password"}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={(e) => submitEnter(e)}
              iconEnd={
                <IconButton
                  disableRipple
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{
                    "&.Mui-focusVisible": {
                      color: "var(--color-black)",
                      backgroundColor: "var(--color-grey-light)"
                    },
                  }}
                >
                  {showPassword ? <VisibilityOff style={{ color: 'var(--color-grey)' }} /> : <Visibility style={{ color: 'var(--color-grey)' }} />}
                </IconButton>
              }
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '5px' }}>
              <M_Button
                height={30}
                label={"Recuperar password"}
                labelColor={"var(--color-black)"}
                onClick={() => setOpenModalPassword(true)}
                borderRadius={5}
                backgroundColor={"var(--color-white)"}
                borderColor={"var(--color-white)"}
              />
            </div>
            {email && password ?
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '233px' }}>
                  <ReCAPTCHA
                    sitekey={apiKey}
                    onChange={reCaptchaButton}
                    onExpired={CallBackReCaptchaButton}
                    style={{ transform: 'scale(0.77)', transformOrigin: '0 0' }}
                    badge={'bottomright'}
                  />
                </div>
              </div>
              : <></>
            }
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <div style={{ marginBottom: '5px' }}>
                <M_Button
                  height={40}
                  width={85}
                  bold
                  label={t("LoginButtonName")}
                  labelColor={'var(--color-white)'}
                  onClick={!verified ? {} : () => handleSubmit()}
                  borderRadius={15}
                  hoverEffect
                  backgroundColor={"var(--color-orange)"}
                  borderColor={"var(--color-orange)"}
                  disabled={!verified}
                />
              </div>
              <div>
                <M_Button
                  height={30}
                  width={70}
                  bold
                  label={"Criar nova conta"}
                  labelColor={"var(--color-black)"}
                  onClick={() => setOpenModalRegister(true)}
                  borderRadius={5}
                  backgroundColor={"var(--color-white)"}
                  borderColor={"var(--color-white)"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalRegister openModalRegister={openModalRegister} setOpenModalRegister={setOpenModalRegister} />
      <ModalChange openModalPassword={openModalPassword} setOpenModalPassword={setOpenModalPassword} />
    </div >
  )
}