import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import "./i18next"
import axios from 'axios'
import { defaultURl } from './components/utils/url.service'

const root = ReactDOM.createRoot(document.getElementById('root'))

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("tokenMaeil")
    if (!config.url.includes('identity/forgetpass') && !config.url.includes('identity/register') && !config.url.includes('identity/updatePartner')) {
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      else {
        if (config.url !== defaultURl + 'identity/login')
          window.location.href = '/login'
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = '/login'
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

root.render(
  <App />
)

reportWebVitals()